import React, { Component } from "react";
import { t, jt } from "ttag";
import { toast } from "react-toastify";

import { fetchPost, setToken, handler, unfocusAll, MODE, friendlyPassScore } from "../util";
import { SubmitButton, Progress } from "./shared";

type RegisterProps = {
  updateHandler: (register?: boolean) => void,
}

type RegisterState = {
  name?: string,
  email?: string,
  password?: string,
  confirmPassword?: string,
  acceptTerms?: string,
  passScore?: number,
  submitEnabled?: boolean,
}

export default class RegisterComponent extends Component<RegisterProps, RegisterState> {
  _passwordCheck?: (pass: string, inputs?: string[]) => { score: number } = undefined;

  passwordCheck = (pass?: string) => {
    if (!this._passwordCheck) return 0;
    return this._passwordCheck(pass || this.state.password || "", [this.state.name || "", this.state.email || ""]).score;
  };


  constructor(props: RegisterProps) {
    super(props);
    this.state = {
      name: '', email: '', password: '', confirmPassword: '',
      acceptTerms: '', passScore: 0, submitEnabled: true
    };

    import("zxcvbn").then(({ default: zxcvbn }) => { this._passwordCheck = zxcvbn; });
  }

  // Keep the state up to date with the fields values
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = event.target;
    const value = t.type === "checkbox" ? t.checked + "" : t.value;
    const name = t.name;

    if (name === "password") {
      this.setState({ passScore: this.passwordCheck(value) });
    }

    if (name === "password" || name === "confirmPassword") {
      t.setCustomValidity("");
    }

    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    unfocusAll();

    const strongPass = (this.state.passScore || 0) >= 2;
    const passField = document.getElementById("registerPass")! as HTMLInputElement;
    passField.setCustomValidity(strongPass ? "" : t`Password is not strong enough`);

    const passwordsSame = this.state.password === this.state.confirmPassword;
    const confirmPassField = document.getElementById("registerConfirmPass")! as HTMLInputElement;
    confirmPassField.setCustomValidity(passwordsSame ? "" : t`The passwords don't match`);

    if (!strongPass || !passwordsSame) {
      passField.reportValidity();
      confirmPassField.reportValidity();
      return;
    }

    this.setState({ submitEnabled: false });

    const { email, password, confirmPassword, name, acceptTerms } = this.state;
    let body = JSON.stringify({ email, password, confirmPassword, name, acceptTerms });
    //https://account.comelitgroup.com/#/?callback=<nuestra url cubikcomelit...>  
    try {
      const res = await fetchPost('/api/auth/register', body, false);
      const text = await res.clone().text(); // Clone because can't consume twice
      if (text.indexOf("__maintenance__") !== -1) {
        throw new Error(t`Webpage offline for maintenance. Please come back later`);
      }

      const json = await res.json();

      if (!res.ok) {
        if (res.status === 401 && json.error === "signups_disabled") {
          throw new Error(t`New user signups are disabled at the moment`);
        }

        if (res.status !== 422) {
          throw new Error(t`Error connecting to the server`);
        }
        const { errors } = json;
        const ret: string[] = [];

        errors["email"]?.forEach((e: string) => {
          if (e === "validation.required")
            ret.push(t`The email field is required`);
          if (e === "validation.email")
            ret.push(t`Please input a valid email address`);
          if (e === "validation.unique")
            ret.push(t`The email introduced already exists`);
        });

        errors["acceptTerms"]?.forEach((e: string) => {
          if (e === "validation.accepted")
            ret.push(t`Please accept the Terms and Conditions`);
        });

        errors["password"]?.forEach((e: string) => {
          if (e === "validation.min.string") {
            ret.push(t`Password is not strong enough`);
          }
        });

        errors["confirmPassword"]?.forEach((e: string) => {
          if (e === "validation.same")
            ret.push(t`The passwords don't match`);
        });

        if (ret.length > 0) {
          throw new Error(ret.join("||"));
        }

        throw new Error(t`Unknown error`);
      }

      setToken(json.token);
      this.props.updateHandler();

    } catch (e) {
      let split = ((e as any)?.message as string).split("||");
      if (split.length === 1) {
        return toast.error(split[0]);
      }

      const error = <ul>
        {split.map((e, i) => <li key={i}>{e}</li>)}
      </ul>;

      return toast.error(error, { autoClose: 8000 });
    } finally {
      this.setState({ submitEnabled: true });
    }
  }

  render() {
    const privacy_policy = <a key="privacy" className="text-primary"
      href={MODE === "ing" ? t`https://ingeniumsl.com/website/en/privacy-policy/` : t`https://besknx.com/website/en/privacy-policy/`}
      target="_blank" rel="noopener noreferrer">{t`Privacy Policy`}</a>;

    // Password length
    const n = 8;

    return (
      <div className="card-body">
        <h4 className="card-title">{t`New user`}</h4>

        <form action="." onSubmit={handler(this.handleSubmit)} method="post">
          <div className="form-group">
            <input type="text" autoComplete="nickname" required className="form-control text-light input-field" name="name"
              id="registerName" value={this.state.name} onChange={this.handleChange} placeholder={t`Name`} />
          </div>

          <div className="form-group">
            <input type="email" autoComplete="email" required className="form-control text-light input-field" name="email"
              id="registerMail" value={this.state.email} onChange={this.handleChange} placeholder={t`Email address`} />
          </div>

          <div className="form-group pb-1">
            <input type="password" autoComplete="new-password" required className="form-control text-light input-field" minLength={n}
              name="password" id="registerPass" value={this.state.password} onChange={this.handleChange} placeholder={t`Password`} />
          </div>

          <div className="pb-3 pl-4" style={{ width: "60%" }}>
            <Progress percent={Math.max((this.state.passScore || 0) * 25, 7)} />
            <small className="text-muted">{t`Password Strength:` + " " + friendlyPassScore(this.state.passScore || 0)}</small>
          </div>

          <div className="form-group">
            <input type="password" autoComplete="new-password" required className="form-control text-light input-field"
              name="confirmPassword" id="registerConfirmPass" value={this.state.confirmPassword} placeholder={t`Confirm password`}
              onChange={this.handleChange} />
          </div>

          <div className="form-group form-check">
            <input type="checkbox" required className="form-check-input" name="acceptTerms"
              id="registerAcceptTerms" checked={this.state.acceptTerms === "true"} onChange={this.handleChange} />
            <label className="form-check-label small text-muted" htmlFor="registerAcceptTerms">
              {jt`I've read and accept the ${privacy_policy}`}
            </label>
          </div>

          <div className="d-flex justify-content-between">
            <button type="button" className="btn btn-secondary"
              onClick={() => this.props.updateHandler(false)}>
              &lt; {t`Back`}
            </button>

            <SubmitButton text={t`Register`} loadingText={t`Loading...`} className="bg-dark btn-outline-primary"
              enabled={!!this.state.submitEnabled} />
          </div>
        </form>
      </div>
    );
  }
}
