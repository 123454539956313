import React, { SVGProps } from "react";

export const BrandSize = 255; // 510 / 2

export const BrandBes2 = (props: SVGProps<SVGPathElement>) => <path {...props} d="M122.68 237.62h-2.36v-7.7l2.18-.07c.8 0 1.42.17 1.87.52.45.34.68.83.68 1.46 0 .61-.32 1.09-.97 1.45.75.26 1.2.74 1.37 1.43.03-.81.31-1.47.82-1.99.55-.55 1.21-.83 1.98-.83.82 0 1.47.24 1.97.73.27.27.47.58.59.93v-.12c0-.49.19-.87.56-1.14.37-.27.85-.41 1.43-.41.59 0 1.14.13 1.65.39l-.38 1.02c-.29-.25-.69-.37-1.2-.37-.46 0-.69.18-.69.55 0 .14.08.27.23.39.15.12.47.27.97.47.49.19.85.43 1.07.71.22.28.33.61.33 1 0 .52-.19.94-.58 1.24-.39.3-.91.45-1.58.45-.38 0-.68-.03-.91-.09-.22-.06-.51-.18-.87-.37l.47-1.05c.39.31.84.47 1.33.47.51 0 .77-.18.77-.55 0-.21-.08-.39-.23-.53s-.45-.3-.9-.48c-.7-.29-1.14-.66-1.34-1.12l.03.47c0 .16-.04.4-.11.73h-4.03c.02.45.18.8.46 1.05s.67.37 1.15.37c.6 0 1.06-.16 1.37-.47l.51 1c-.46.38-1.15.56-2.07.56-.86 0-1.54-.25-2.04-.75-.36-.36-.59-.82-.69-1.39-.06.58-.32 1.06-.78 1.42-.54.42-1.22.62-2.06.62zm4.19-3.4h2.77c-.09-.82-.55-1.24-1.37-1.24-.75 0-1.21.41-1.4 1.24zm-5.18-3.21v1.85l.66.02c.89 0 1.34-.33 1.34-1 0-.59-.41-.89-1.22-.89l-.78.02zm0 2.92v2.55l.67.04c.61 0 1.05-.1 1.33-.31s.42-.55.42-1.03c0-.44-.13-.76-.38-.96-.26-.2-.7-.3-1.34-.3l-.7.01z" />;

export const BrandBes = (props: SVGProps<SVGPathElement>) => 

<>
		<path {...props} d="M5.4999 0C5.07138 0 4.65248 0.117297 4.29618 0.337058C3.93988 0.55682 3.66217 0.869175 3.49818 1.23462C3.33419 1.60007 3.29128 2.00221 3.37488 2.39017C3.45848 2.77812 3.66484 3.13449 3.96785 3.41419C4.27086 3.69389 4.65692 3.88437 5.07721 3.96154C5.4975 4.03871 5.93314 3.99911 6.32905 3.84773C6.72495 3.69636 7.06334 3.44002 7.30141 3.11112C7.53949 2.78222 7.66656 2.39555 7.66656 1.99999C7.66656 1.46956 7.43829 0.960853 7.03196 0.585783C6.62564 0.210712 6.07454 0 5.4999 0ZM5.4999 2.83998C5.31612 2.83801 5.13706 2.7859 4.98529 2.69021C4.83351 2.59452 4.71578 2.45953 4.64693 2.30222C4.57807 2.14492 4.56116 1.97232 4.59832 1.80616C4.63548 1.64001 4.72505 1.48771 4.85577 1.36843C4.98649 1.24916 5.1525 1.16824 5.33292 1.13586C5.51335 1.10348 5.70012 1.12108 5.86973 1.18646C6.03934 1.25183 6.18421 1.36205 6.28611 1.50326C6.38801 1.64446 6.44239 1.81032 6.4424 1.97999C6.44241 2.09377 6.41796 2.20642 6.37046 2.31142C6.32297 2.41642 6.25337 2.51167 6.1657 2.59166C6.07804 2.67165 5.97405 2.73478 5.85976 2.77741C5.74547 2.82004 5.62316 2.8413 5.4999 2.83998Z"/>
		<path {...props} d="M3.10016 2.42217C3.01101 2.54991 2.8862 2.65342 2.73869 2.72195C2.59118 2.79049 2.42635 2.82154 2.26134 2.81188C2.02866 2.79993 1.80894 2.70976 1.64415 2.55861C1.47937 2.40746 1.38107 2.20591 1.36804 1.99248C1.36055 1.87596 1.37931 1.75928 1.42317 1.64971C1.46702 1.54014 1.53503 1.44003 1.62294 1.35564C1.71085 1.27126 1.81678 1.20439 1.93412 1.15923C2.05145 1.11406 2.17768 1.09157 2.30491 1.09314C2.4625 1.0915 2.618 1.12636 2.75701 1.19448C2.89602 1.2626 3.01404 1.36179 3.10016 1.48286C3.19657 1.11212 3.39114 0.768903 3.66664 0.483592C3.23552 0.143333 2.67644 -0.0288052 2.10882 0.00394522C1.75677 0.014318 1.4127 0.102832 1.10611 0.261903C0.799513 0.420973 0.539535 0.645857 0.348452 0.917283C0.15737 1.18871 0.0408801 1.49858 0.00896324 1.82036C-0.0229536 2.14213 0.030655 2.4662 0.165196 2.7648C0.299736 3.0634 0.5112 3.32763 0.781461 3.53485C1.05172 3.74206 1.37272 3.88608 1.71696 3.95456C2.06119 4.02305 2.4184 4.01396 2.75796 3.92807C3.09752 3.84217 3.40933 3.68205 3.66664 3.4614C3.39114 3.17609 3.19657 2.83288 3.10016 2.46214V2.42217Z"/>
		<path {...props} d="M9.14896 1.7433L9.88474 3.99997H10.7818L11.5075 1.7433H11.5377V3.99997H12.6666V0H11.0136L10.3484 2.14972H10.3181L9.65291 0H7.99994V3.99997H9.11872V1.7433H9.14896Z"/>
		<path {...props} d="M12.9999 3.99997H16.6666V2.8556H14.4005V2.47057H15.7897V1.49732H14.4005V1.0909H16.6666V0H12.9999V3.99997Z"/>
		<path {...props} d="M23.1978 3.99997H24.5109V1.084H25.6665V0H22.0423V1.084H23.1978V3.99997ZM16.9999 3.99997H20.1514V2.84009H18.3235V0H16.9999V3.99997Z"/>
		<path {...props} d="M21.6665 0H20.3332V3.99997H21.6665V0Z"/>
</>

export const BrandIng = (props: SVGProps<SVGPathElement>) => <>
	<path {...props} d="M109.65,228.98c0.15,0,0.28,0.05,0.39,0.16c0.11,0.11,0.16,0.23,0.16,0.38c0,0.15-0.05,0.28-0.16,0.39
		c-0.11,0.1-0.23,0.16-0.39,0.16c-0.15,0-0.28-0.05-0.39-0.16c-0.11-0.11-0.16-0.24-0.16-0.39s0.05-0.28,0.16-0.39
		C109.37,229.03,109.5,228.98,109.65,228.98z M109.17,235.83v-4.23h-0.68v-0.74h1.56v4.97H109.17z"/>
	<path {...props} d="M114.51,235.83v-2.89c0-0.53-0.08-0.9-0.24-1.11c-0.16-0.21-0.42-0.32-0.8-0.32c-0.2,0-0.41,0.06-0.63,0.18
		c-0.22,0.12-0.39,0.27-0.51,0.45v3.69h-0.88v-4.97h0.6l0.28,0.64c0.29-0.49,0.77-0.73,1.42-0.73c1.09,0,1.63,0.66,1.63,1.99v3.08
		H114.51z"/>
	<path {...props} d="M116.46,237.19l0.48-0.71c0.51,0.34,0.99,0.51,1.42,0.51c0.4,0,0.71-0.07,0.94-0.21
		c0.23-0.14,0.35-0.31,0.35-0.51c0-0.4-0.29-0.6-0.87-0.6c-0.1,0-0.28,0.03-0.54,0.07c-0.26,0.05-0.46,0.07-0.61,0.07
		c-0.71,0-1.06-0.27-1.06-0.8c0-0.16,0.08-0.31,0.25-0.45c0.17-0.13,0.37-0.23,0.62-0.29c-0.7-0.33-1.05-0.9-1.05-1.71
		c0-0.52,0.18-0.95,0.54-1.3c0.36-0.35,0.81-0.52,1.34-0.52c0.49,0,0.87,0.1,1.15,0.3l0.44-0.53l0.58,0.54l-0.53,0.4
		c0.23,0.29,0.34,0.68,0.34,1.15c0,0.5-0.16,0.92-0.47,1.26c-0.31,0.34-0.72,0.54-1.23,0.58l-0.73,0.07
		c-0.09,0.01-0.2,0.04-0.35,0.1c-0.15,0.06-0.22,0.13-0.22,0.22c0,0.13,0.15,0.19,0.45,0.19c0.13,0,0.34-0.02,0.62-0.07
		c0.28-0.05,0.49-0.07,0.63-0.07c0.49,0,0.87,0.12,1.14,0.35c0.28,0.23,0.41,0.55,0.41,0.97c0,0.46-0.21,0.83-0.62,1.11
		c-0.41,0.28-0.93,0.42-1.55,0.42c-0.32,0-0.66-0.06-1.02-0.17S116.68,237.35,116.46,237.19z M118.33,231.48
		c-0.31,0-0.56,0.11-0.75,0.33c-0.19,0.22-0.29,0.48-0.29,0.79c0,0.35,0.09,0.63,0.28,0.86c0.19,0.23,0.44,0.34,0.76,0.34
		c0.31,0,0.56-0.11,0.73-0.33c0.18-0.22,0.26-0.51,0.26-0.87c0-0.31-0.1-0.57-0.29-0.79C118.85,231.58,118.61,231.48,118.33,231.48z
		"/>
	<path {...props} d="M125.59,233.43h-3.58c0,0.58,0.16,1.03,0.48,1.34c0.28,0.27,0.64,0.41,1.09,0.41c0.5,0,0.92-0.15,1.26-0.44
		l0.37,0.63c-0.14,0.14-0.34,0.25-0.63,0.35c-0.35,0.13-0.75,0.2-1.18,0.2c-0.62,0-1.16-0.21-1.59-0.64
		c-0.49-0.47-0.73-1.09-0.73-1.88c0-0.82,0.25-1.48,0.75-1.97c0.45-0.44,0.97-0.66,1.58-0.66c0.71,0,1.26,0.2,1.67,0.6
		c0.39,0.38,0.58,0.89,0.58,1.53C125.66,233.09,125.64,233.26,125.59,233.43z M123.45,231.51c-0.39,0-0.72,0.13-0.99,0.38
		c-0.25,0.24-0.4,0.54-0.43,0.9h2.75c0-0.36-0.11-0.65-0.33-0.89C124.21,231.64,123.88,231.51,123.45,231.51z"/>
	<path {...props} d="M129.65,235.83v-2.89c0-0.53-0.08-0.9-0.24-1.11c-0.16-0.21-0.42-0.32-0.8-0.32c-0.2,0-0.41,0.06-0.63,0.18
		c-0.22,0.12-0.39,0.27-0.51,0.45v3.69h-0.88v-4.97h0.6l0.28,0.64c0.29-0.49,0.77-0.73,1.42-0.73c1.09,0,1.63,0.66,1.63,1.99v3.08
		H129.65z"/>
	<path {...props} d="M132.69,228.98c0.15,0,0.28,0.05,0.39,0.16c0.11,0.11,0.16,0.23,0.16,0.38c0,0.15-0.05,0.28-0.16,0.39
		c-0.11,0.1-0.23,0.16-0.39,0.16c-0.15,0-0.28-0.05-0.39-0.16c-0.11-0.11-0.16-0.24-0.16-0.39s0.05-0.28,0.16-0.39
		C132.41,229.03,132.54,228.98,132.69,228.98z M132.21,235.83v-4.23h-0.68v-0.74h1.56v4.97H132.21z"/>
	<path {...props} d="M135.32,230.86v3.17c0,0.77,0.33,1.15,1,1.15c0.29,0,0.56-0.08,0.8-0.25s0.4-0.36,0.48-0.58v-3.49h0.88v4.97
		h-0.88v-0.69c-0.1,0.19-0.3,0.37-0.6,0.54c-0.3,0.16-0.59,0.24-0.87,0.24c-0.54,0-0.96-0.16-1.25-0.47
		c-0.29-0.31-0.43-0.76-0.43-1.33v-3.26H135.32z"/>
	<path {...props} d="M145.43,235.83v-3.15c0-0.78-0.34-1.17-1.02-1.17c-0.21,0-0.41,0.07-0.6,0.2c-0.19,0.13-0.31,0.28-0.38,0.45
		v3.67h-0.88v-3.53c0-0.24-0.09-0.44-0.28-0.58c-0.18-0.14-0.43-0.21-0.73-0.21c-0.18,0-0.36,0.07-0.57,0.2
		c-0.2,0.14-0.34,0.29-0.42,0.45v3.67h-0.88v-4.97h0.58l0.29,0.58c0.34-0.45,0.77-0.67,1.28-0.67c0.71,0,1.21,0.22,1.49,0.66
		c0.1-0.19,0.28-0.35,0.55-0.47c0.27-0.13,0.55-0.19,0.83-0.19c0.51,0,0.91,0.15,1.19,0.46c0.28,0.3,0.42,0.73,0.42,1.28v3.32
		H145.43z"/>
</>;