import React, { useState } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { Modal } from "../shared";
import {  ProjectData } from "../../types";

type SendOrderModalProps = {
  buttonId: string;
  onConfirmSend: () => void,
};

const SendOrderModalComponent: React.FC<SendOrderModalProps> = ({buttonId, onConfirmSend}) => {
  const handleDelete = async () => {

    try {
      document.getElementById(buttonId)?.click();
      onConfirmSend();
    } catch (error) {
      toast.error(t`Error sending order`);
    } 
  };

  const footer = (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end",  width: "100%"}}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => document.getElementById(buttonId)?.click()} 
          style={{marginRight: "10px"}}
        >
          {t`Cancel`}
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handleDelete}
        >
         {t`Send`}
        </button>
      </div>
    </>
  );

  return (
    <Modal btnId={buttonId} title={t`Finish project`} footer={footer}>
      <div className="modal-body">
        <p>{t`Once the project is finished, it cannot be modified again and an order must be placed with the new generated codes.`}</p>
      </div>
    </Modal>
  );
};

export default SendOrderModalComponent;
