import React, { useState } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { Modal } from "../shared";
import { ProjectData } from "../../types";

type DeleteProjectModalProps = {
  buttonId: string;
  project: ProjectData,  
  onConfirmDeleted: () => void,
};

const DeleteProjectModalComponent: React.FC<DeleteProjectModalProps> = ({buttonId, project, onConfirmDeleted}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {

    setIsDeleting(true);


    try {
      document.getElementById(buttonId)?.click();
      onConfirmDeleted();
    } catch (error) {
      toast.error(t`Error deleting project`);
    } finally {
      setIsDeleting(false);
    }
  };

  const footer = (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end",  width: "100%"}}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => document.getElementById(buttonId)?.click()} 
          style={{marginRight: "10px"}}
        >
          {t`Cancel`}
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={handleDelete}
          disabled={isDeleting}
        >
         {t`Delete`}
        </button>
      </div>
    </>
  );

  return (
    <Modal btnId={buttonId} title={t`Delete project' ${project.name}'`} footer={footer}>
      <div className="modal-body">
        <p>{t`Are you sure you want to delete this project? This action cannot be undone.`}</p>
      </div>
    </Modal>
  );
};

export default DeleteProjectModalComponent;
