import React, { Component } from "react";
import AdminPageComponent from "./components/AdminPage";
import ProductionPageComponent from "./components/ProductionPage"
import LoginComponent from "./components/Login";
import MainPageComponent from "./components/MainPage";
import RegisterComponent from "./components/Register";
import ResetPassComponent from "./components/ResetPass";
import { LocaleSelector } from "./components/shared";
import { UserData } from "./types";
import { fetchGet, getToken, setToken, pullQuery, boxShadow, loading, OVERLAY } from "./util";
import "./App.css";

type ResetData = { email: string, token: string };

type InitialState = {
  loading: boolean,
  register: boolean,
  user?: UserData,

  reset?: ResetData,
}

export default class App extends Component<any, InitialState> {
  constructor(props: any) {
    super(props);
    this.state = { loading: true, register: false, user: undefined };
  }

  updateHandler = (register?: boolean) => {
    if (register !== undefined) {
      this.setState({ register: register });
    }

    this.componentDidMount();
  }

  componentDidMount() {
    let state = (user?: any) => {
      this.setState({ loading: false, user: user }, () => {
        OVERLAY?.classList.remove("initial");
        if (!user) loading(false);
      });
    };
    const urlParams = pullQuery(true);
    const resetEmail = urlParams.get("email"), resetToken = urlParams.get("token");

    if (resetEmail && resetToken) {
      pullQuery(false);
      this.setState({ reset: { email: resetEmail, token: resetToken } });
    }

    if (!this.state.reset && getToken()) {
      // Check that the token is valid
      fetchGet('/api/user/profile')
        .then(res => {
          if (res.ok) {
            res.json().then(state);
          } else {
            setToken(undefined); // Remove token
            state(undefined);
          }
        });
    } else {
      state(undefined);
    }
  }

  render() {
    // Add loading cover
    const { loading, user, reset, register } = this.state;

    return <>
      <div className="App">
        {!loading && this.content(register, reset, user)}
      </div>
    </>;
  }
  
  //mostrar versión Dani
  //<small className="text-light">Version {process.env.REACT_APP_VERSION} ({process.env.REACT_APP_GIT_DATE}, {process.env.REACT_APP_GIT_SHA!.slice(0, 3)})</small>
 
  content(register: boolean, reset?: ResetData, user?: UserData) {
    if (user?.rank.startsWith("admin")) {
      return <AdminPageComponent updateHandler={this.updateHandler} rank={user.rank} />
    } 
    else if( user?.rank == "user-production")
    {
      return <ProductionPageComponent updateHandler={this.updateHandler} rank={user.rank} />
    }
    if (user) {
      return <MainPageComponent user={user} updateHandler={this.updateHandler} />
    } else {
      return (
        <div className="container h-100">
          <div style={{ position: "absolute", bottom: 15, left: 15, opacity: .4 }}>            
            <small className="text-light">Version 1.5.1 (2024-09-27, 7cc)</small> 
          </div>
          <LocaleSelector id="locale-selector" style={{ boxShadow }} />

          <div className="row h-100 justify-content-center">
            <div className="h-100 col-12 col-sm-10 col-md-7 col-lg-5">
              <div id="initialCard" className="bg-transparent text-light border-none">
                {this.contentInner(register, reset)}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  contentInner(register: boolean, reset?: ResetData) {
    if (reset) {
      return <ResetPassComponent email={reset.email} token={reset.token}
        onSuccess={() => this.setState({ reset: undefined })} />;
    }
    return <CardContent register={register} updateHandler={this.updateHandler} />;
  }

}

function CardContent(props: any) {
  const { register, updateHandler } = props;

  if (register) {
    return <RegisterComponent updateHandler={updateHandler} />;
  } else {
    return <LoginComponent updateHandler={updateHandler} />;
  }
}

