import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { Modal } from "../shared";

type NewProjectModalProps = {
  buttonId: string;
  newProjectName: string;
  onCreateProject: (name: string) => void;  // Pasamos la función como prop
};

const NewProjectModalComponent: React.FC<NewProjectModalProps> = ({  buttonId,  newProjectName,  onCreateProject}) => {
  const [projectName, setProjectName] = useState(newProjectName);
  
  useEffect(() => {
    setProjectName(newProjectName);
  }, [newProjectName]);

  const handleCreate = () => {
    if (projectName.trim() === "") {
      toast.error(t`Please enter a project name.`);
      return;
    }

    document.getElementById(buttonId)?.click();
    onCreateProject(projectName); 
  };

  const footer = (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => document.getElementById(buttonId)?.click()}
          style={{ marginRight: "10px" }}
        >
          {t`Cancel`}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleCreate}
        >
          {t`Save`}
        </button>
      </div>
    </>
  );
  return (
    <Modal btnId={buttonId} title={t`New project`} footer={footer}>
      <div className="modal-body">
        <p>{t`Input the new project name`}</p>
        <input
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          className="form-control"
          placeholder={t`Project name`}
        />
      </div>
    </Modal>
  );
};

export default NewProjectModalComponent;